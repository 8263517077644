
export default {
  version: "1.0.3",
  tips: {
    errCode: {
      400: "参数错误",
      403: "权限受限，请联系管理员",
      404: "请求链接不存在,请联系管理员",
      405: "请求方式不正确,应为POST/GET",
      500: "服务器错误,请联系管理员",
      default: "无效的请求,状态码($),请联系管理员",
      other: "未知错误，请联系管理排查"
    }
  },
  globalProject: 3, // 公众号openid
  project: 53,
  projectId: 1, // 直播id,正式服记得改
  // branch:"dev",
  logo: 'https://guzhuanyizhoukan.dev.medflying.com/files/公众号logo.png',
  env_version: "release",//正式服记得改 ENV_VERSION：要打开的小程序版本,正式版为release，体验版为trial，开发版为develop，仅在微信外打开时生效。注意：若不填写，则默认打开正式版小程序
  localhost: "https://guzhuanyizhoukan.medflying.com",//正式服记得改
  host: "https://guzhuanyizhoukan.medflying.com",//正式服记得改
  report: "https://guzhuanyizhoukan.medflying.com",//正式服记得改
  ws: "ws://guzhuanyizhoukan.medflying.com/ws",
  urlPrefix: "/ui",
  title: "飞派文献骨转领域",
  prefix: "",
  types: {
    exports: {
      zip: 'application/zip',
      xlsx: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=utf-8'
    },
    status: {//会议状态
      0: '待召开',
      1: '进行中',
      2: '已结束',
      3: '时长不足系统自结',
      4: '超时未召开'
    },
    auditStatus: {
      0: '未审核',
      10: '审核中 ',
      20: '审核成功',
      25: "基金会审核中",
      30: "审核失败",
      35: '付款失败更新返回基金会审核中',
      40: "基金会审核成功",
      50: "基金会审核失败"
    },
    pay: {
      0: "付款中",
      1: "付款成功",
      2: "付款失败",
    }
  },
  // popups: {
  //   infloor: '进入课堂',
  //   outfloor: '离开课堂',
  //   academic: '教授',
  //   phase: {
  //     1: "欢迎{doctor}教授进入课堂,本场会议的讲题：《{courseware}》，跟专家一起学习吧!",
  //     2: "现在进入互动答疑环节，欢迎大家积极提问！",
  //     3: "本场会议已结束，感谢您的参与。",
  //   },
  // },
  wxinterface: [
    "updateAppMessageShareData",
    "onMenuShareAppMessage",
    "onMenuShareTimeline",
    "updateTimelineShareData"
  ],
  images: {
    "tip": "https://bzjt-mp4.oss-cn-zhangjiakou.aliyuncs.com/app/guzhuanyizhoukan/tip.jpg",
    "wait": "https://bzjt-mp4.oss-cn-zhangjiakou.aliyuncs.com/app/guzhuanyizhoukan/live-cover.jpg",
    "leave": "https://bzjt-mp4.oss-cn-zhangjiakou.aliyuncs.com/app/guzhuanyizhoukan/head-cover.jpg",
    "discuss": "https://bzjt-mp4.oss-cn-zhangjiakou.aliyuncs.com/app/guzhuanyizhoukan/discuss.jpg",
    "info": {
      "banner": "https://bzjt-mp4.oss-cn-zhangjiakou.aliyuncs.com/app/guzhuanyizhoukan/banner.png",
      "code": "https://bzjt-mp4.oss-cn-zhangjiakou.aliyuncs.com/app/guzhuanyizhoukan/qrcode.jpg",
      "time": "https://bzjt-mp4.oss-cn-zhangjiakou.aliyuncs.com/app/guzhuanyizhoukan/clock.png",
      "date": "https://bzjt-mp4.oss-cn-zhangjiakou.aliyuncs.com/app/guzhuanyizhoukan/date.png",
      "right-sj": "https://bzjt-mp4.oss-cn-zhangjiakou.aliyuncs.com/app/guzhuanyizhoukan/right-sj.png",
      "right-arrow": "https://bzjt-mp4.oss-cn-zhangjiakou.aliyuncs.com/app/guzhuanyizhoukan/right-arrow.png",
      "left-arrow": "https://bzjt-mp4.oss-cn-zhangjiakou.aliyuncs.com/app/guzhuanyizhoukan/left-arrow.png",
      "bg": "https://files.medflying.com/new_files/骨转移周刊/gzyzk/png/bg.png"
    },
    "infoFiles": {
      "banner": "https://files.medflying.com/new_files/飞派文献骨转领域/home/png/banner.png",
      "code": "https://files.medflying.com/new_files/飞派文献骨转领域/home/jpg/qrcode.jpg",
      "time": "https://files.medflying.com/new_files/飞派文献骨转领域/home/png/clock.png",
      "date": "https://files.medflying.com/new_files/飞派文献骨转领域/home/png/date.png",
      "right-sj": "https://files.medflying.com/new_files/飞派文献骨转领域/home/png/right-sj.png",
      "right-arrow": "https://files.medflying.com/new_files/飞派文献骨转领域/home/png/right-arrow.png",
      "left-arrow": "https://files.medflying.com/new_files/飞派文献骨转领域/home/png/left-arrow.png",
      "bg": "https://files.medflying.com/new_files/飞派文献骨转领域/home/png/bg (1).png"
    },
    "play": "https://bzjt-mp4.oss-cn-zhangjiakou.aliyuncs.com/app/v2/play.gif",
    "pause": "https://bzjt-mp4.oss-cn-zhangjiakou.aliyuncs.com/app/v2/pause.png"
  },
  popups: {
    infloor: '进入课堂',
    outfloor: '离开课堂',
    academic: '教授',
    courseware: "本场会议的讲题是“$$”，欢迎$$专家开场致辞",
    v2: {
      phase: {
        1: "欢迎{doctor}教授进入课堂,本场会议的讲题：《{courseware}》，跟专家一起学习吧!",
        2: "现在进入互动答疑环节，欢迎大家积极提问！",
        3: "本场会议已结束，感谢您的参与。",
      },
      welcome: {
        content: [['欢迎来到直播间']],
        type: [],
        button: ["确认"]
      },
      play: {
        content: [['专家回来了，点击继续观看吧']],
        type: [],
        button: ["确认"]
      },
    }
  },
  color: "#f2674a"
}