import { createApp } from 'vue'
import App from './App.vue'
import store from './store'
import router from './router'
import '@/assets/css/reset.css'
import ElementPlus from 'element-plus';
// import { LocaleInjectionKey, localeProviderMaker } from 'element-plus';
import zhCn from 'element-plus/es/locale/lang/zh-cn'
import '@/assets/css/theme/index.scss'
import '@/assets/css/index.css'
import '@/assets/css/media.css'
import 'viewerjs/dist/viewer.css'
import Viewer from 'v-viewer'
import tips from '@/common/tips'
import axios from '@/common/axios'
import tools from '@/common/tool'
import urls from '@/common/urls'
import config from '@/common/config'
import Video from 'video.js'// (runtime-only or standalone) has been set in webpack.base.conf with an alias.

import 'video.js/dist/video-js.css'
// import vConsole from "@/common/vconsole"
// import lazyPlugin from 'vue3-lazy';
let defaultImage = require("@/assets/images/live/loading.jpg")
console.log(defaultImage)
let Vue = createApp(App)
Vue.use(router)
Vue.use(store)


Vue.use(ElementPlus, { locale: zhCn })
Vue.use(Viewer)

Viewer.setDefaults({
  title: false,
  zoomable: true,
  navbar: false,
  toolbar: {
    zoomIn: 1,
    zoomOut: 1
  }
});
// const opts = { locale: zhCn };
// const localeProvides = localeProviderMaker(opts.locale);
// Vue.provide(LocaleInjectionKey, localeProvides);

Vue.config.globalProperties.$video = Video
Vue.config.globalProperties.$tips = tips
Vue.config.globalProperties.$axios = axios
Vue.config.globalProperties.$config = config
Vue.config.globalProperties.$urls = urls
Vue.config.globalProperties.$tools = tools

// Vue.directive('clickoutside', function (el) {
//   let dom = el.querySelector(".el-input__inner");
//   dom.addEventListener("blur", function () {
//     setTimeout(() => {
//       dom.removeAttribute("readonly");
//     }, 200);
//   });  
// })

Vue.mount('#app')
