// api模块
import config from "@/common/config"
import tool from "@/common/tool"
const urls = {
  openid: "https://server.medflying.com/api/common/wx/openid",//openid
  jssign: 'https://server.medflying.com/api/common/wx/jssign',//获取js SDK
  oauth: '/wx/set_cookie_openid',//验证
  generatescheme: 'https://server.medflying.com/api/common/wx/generatescheme',
  uploads: {
    uploadBase64: "https://files.medflying.com/file_system/filesbase64",
    uploadFile: "https://files.medflying.com/file_system/files",
  },
  login: {
    saleLogin: '/sales/login',
    saleCode: '/sales/send_code',
    userLogin: '/user/login',
    userAdd: '/user/user_add',
    userCode: '/user/send_code',

  },
  live: {
    info: "/user/sign",
    sign: "/user/meet_sign",
    verify: "https://guzhuanyizhoukan.medflying.com/ws/live/live_info",
    meetInfo: '/meet/info',//会议信息
    matchSignDoctor: '/meet/match_sign_doctor',//签到匹配
    meetNumWarning: "/forum/meet_num_warning",// 会议饱和度


  },
  sale: {
    home: '/sales/home',//志愿者首页
    addDoctor: '/sales/user_add',//增加医生
    salesUserHome: '/sales/user_home',//销售-专家首页
    userHome: '/user/home',//专家首页
    patchUserCount: '/sales/patch_user_count',//调整会议次数
    userInfo: '/user/info',//get专家信息  patch完善专家信息
    doctorInfoPlus: '/user/doctor_info_plus',//完善专家信息
    auditJob: '/user/audit_job',//人工审核
    getDoctor: '/user/get_doctor',//智能匹配总库医生
    patchPay: '/meet/patch_pay',//调整支付方式
  },
  courseware: {
    list: "/courseware/list",
    detail: '/user/courseware_info',
    originalHelp: '/literature/original_help'
  },
  literature: {
    list: '/literature/list',//文献 文章列表
    detail: '/literature/detail',//详情
    countInfo: '/literature/count_info',//文献 阅读数，收藏数 详情
    collect: '/literature/collect',//收藏
    like: '/literature/like',//点赞
    collectList: '/literature/my_collect',//收藏列表
    periodsList: '/literature/periods',//收藏列表
    uploadCourseware: '/sales/upload_courseware',//上传课件
    coursewareInfo: '/user/courseware_info',//课件详情
    fieldList: '/literature/filedes',//领域


  },
  meet: {
    create: '/sales/meet_create',//创建会议
    meetInfo: '/user/meet_info',//会议详情
    meetPatch: '/sales/meet_patch',//修改会议 销售
    meetUserPatch: '/user/meet_patch',//修改会议 专家
    signNotice: '/user/sign_notice',//提醒签署劳务协议 
    doctorCreate: '/user/meet_create',//专家创建会议
    addPoster: '/meet/add_poster',//上传海报
    getVersion: '/live/get_version',//  版本
    savePath: "/live/get_path", // 保存进入路径  
    applyCancel: "/meet/apply_cancel",// 申请取消会议
  },
  agreement: {
    doctorSign: '/user/sign',//协议签署
    signDoctorInfo: "/user/sign_doctor_info",//协议信息

  },
  hospital: {
    areaList: "/apply/area_list",//地区列表 
    hospitalList: "/apply/hospital_list",//医院列表
    managerList: "/apply/manager_list",//经理列表 
    managerListN: "/apply/new_manager_list",//经理列表 
    addApply: "/apply/add_apply",//医生申请
    addDetail: "/apply/apply_detail",//医生详情
    patchApply: "/apply/patch_apply",//医生修改
    detail_by_phone: "/apply/apply_detail_by_phone",//根据医生电话获取报名详情
    detail_by_phone_new: "/apply/apply_detail_by_phone_new",//根据医生电话获取报名详情(变更)
    login: `${config.host}/back/api/login`,//报名系统登录
    code: `${config.host}/back/api/sms`,//报名系统验证码
    list: `${config.host}/back/apply/apply_list`, //报名系统-审批列表
    delete: `${config.host}/back/apply/delete_apply`,//报名系统-审批删除
    detail: `${config.host}/back/apply/apply_detail`,//报名系统-审批详情
    update: `${config.host}/back/apply/patch_apply`,//报名系统-审批修改
    audit: `${config.host}/back/apply/audit_apply`,//报名系统-审批审核
    exportAreaList: `${config.host}/back/area/export_area_list`,//报名系统-导出区域报名统计
    changePerson: "/apply/change_person",//报名系统-变更
    getmilitaryHospital: "/user/get_military_hospital",//获取医院标记部队医院
  },
}

let host = tool.isLocal() ? config.localhost : config.host;
for (let key in urls) {
  if (typeof urls[key] == "object") {
    for (let i in urls[key]) {
      if (urls[key][i].includes("http")) continue;

      urls[key][i] = `${host}${config.urlPrefix}${urls[key][i]}`;
    }
    continue
  }
  if (urls[key].includes("http")) continue;
  urls[key] = `${host}${config.urlPrefix}${urls[key]}`;
}
export default urls;